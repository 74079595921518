<template>
  <div>
    <div class="header">
      <div class="header_top">
        当前位置：
        <span class="center">企业管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">用工企业</span>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content: space-between; margin:10px 0;">
      <div style="display: flex; align-items: center">
        <span class="seach_text">用工企业名称：</span>
        <el-input
          placeholder="请输入企业名称"
          v-model="formInline.keywords"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
        <span class="seach_text">企业状态：</span>

        <el-select size="mini" v-model="enable" placeholder="请选择" clearable>
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, i) in stateSelect"
            :key="i"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          style="margin:0 10px;border-radius: 5px;"
          type="primary"
          @click="query()"
        >查询</el-button>
      </div>
      <div>
        <el-button
          size="small"
          style="margin:0 10px;border-radius: 5px;"
          type="primary"
          @click="addEnterprise"
        >新增企业</el-button>
      </div>
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column label="企业名称 " width="280" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column label="企业状态" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.enable"
            active-text="启用"
            inactive-text="禁用"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="enableChange(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="企业备注" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="shuxian">
            <span class="clickColor" @click="goEdit(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
          </div>
          <!-- <div class="shuxian">
            <el-popconfirm
              v-if="!scope.row.is_platform"
              icon="el-icon-circle-check"
              title="确定启用当前企业？"
            >
              <el-button slot="reference" type="text">启用</el-button>
            </el-popconfirm>
            <el-popconfirm v-if="scope.row.is_platform" icon="el-icon-info" title="确定禁用当前企业？">
              <el-button slot="reference" style="color:red" type="text">禁用</el-button>
            </el-popconfirm>

            <el-divider direction="vertical"></el-divider>
          </div>-->
          <div class="shuxian">
            <el-popover
              placement="top"
              width="400"
              trigger="manual"
              :value="visible"
              :ref="'popover-' + scope.row.id"
            >
              <div class="del-pop">
                <img src="../../../assets/del.png" alt />
              </div>
              <div class="del-title">删除企业信息</div>
              <div class="del-sumtitle">确认删除该条企业资料信息？</div>
              <div class="del-btn">
                <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
              </div>
              <el-button
                slot="reference"
                style="color:red"
                type="text"
                @click="pOpen(scope.row.id)"
              >删除</el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <addDialog
      :dialogVisible.sync="addDialogVisible"
      :code.sync="code"
      :title.sync="title"
      @func="addClose()"
    ></addDialog>
  </div>
</template>

<script>
import { api } from '/src/api/base';

export const workEnterpriseDel = api()('work_enterprise.del.json');

export const workEnterpriseList = api('')(
  'work_enterprise.list.json'
);
export const workEnterpriseEdit = api('')(
  'work_enterprise.edit.json'
);
export default {
  components: {
    addDialog: () => import('./add'),

    // ListFilter: () => import("/src/components/filter"),

  },
  data () {
    return {
      visible: false,
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      code: '',
      formInline: {
        keywords: '',
      },
      list: [],
      enable: '',

      addDialogVisible: false,
      title: '',
      // "0"：未认证 "1"：打款中  "2"：打款成功  "3"：认证成功 "4"认证失败
      stateSelect: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '启用',
          value: '1',
        },
        {
          label: '禁用',
          value: '0',
        },
      ],

    };
  },
  computed: {
    classVal: function () {
      return {
        'font-color-warning': true,
      };
    },
  },

  created () {
    this.loadData();
  },
  methods: {
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      this.pClose(id);
    },
    pClose (id) {
      this.$refs['popover-' + id].doClose();
    },
    enableChange (val) {
      console.log(val.enable);
      const params = {
        code: val.code,
        enable: val.enable
      };

      workEnterpriseEdit(params).then(res => {
        console.log(res, 222222);
        this.loadData()
      });
      console.log(val);
    },
    addEnterprise () {
      this.addDialogVisible = true
      this.title = '新建企业'
      this.code = ''
    },
    goEdit (row) {
      this.addDialogVisible = true
      this.title = '编辑企业'
      this.code = row.code
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData () {

      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        enable: this.enable,
        $ignoreRepeat: true,
      };

      workEnterpriseList(params).then(res => {
        this.list = res.list
        this.total = res.total
      });
    },
    del (obj) {
      console.log(obj);
      const params = {
        code: obj.code
      };

      workEnterpriseDel(params).then(() => {
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });

    },
    addClose () {

      this.loadData();


    },
    query () {
      this.pageNumber = 1;
      this.loadData();
    },



  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  .header_top {
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }
    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }
    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}
.table_border {
  margin: 20px 0;
  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }
  .table_demo {
    padding: 20px 0;
    font-size: 10px;
  }
}
.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}
.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}
</style>
